import React from 'react';
import classNames from 'classnames';
import { CarretDown } from '../Icons/Icons';
import './AWSelect.scss';

export const AWSelect = ({ children, ...rest }) => {
  return (
    <div className="awselect-container">
      <CustomSelect type="select" {...rest}>
        {children}
      </CustomSelect>
      <span className={`awselect-carret${(rest.hide) ? '-hide' : ''}`} >
        <CarretDown />
      </span> 
    </div>
  )
}


export class CustomSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultSelectText: props.value ? props.value : "",
      showOptionList: false,
      optionsList: []
    };
  }

  componentDidMount() {
    // Add Event Listner to handle the click that happens outside
    // the Custom Select Container
    document.addEventListener("mousedown", this.handleClickOutside);
    this.getDefaultSelectText(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value || prevProps.children !== this.props.children) {
      this.getDefaultSelectText(this.props);
    }
  }

  componentWillUnmount() {
    // Remove the event listner on component unmounting
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  // This method handles the click that happens outside the
  // select text and list area
  handleClickOutside = e => {
    if (
      !e.target.classList.contains("custom-select-option") &&
      !e.target.classList.contains("selected-text")
    ) {
      this.setState({
        showOptionList: false
      });
    }
  };

  // This method handles the display of option list
  handleListDisplay = () => {
    this.setState(prevState => {
      return {
        showOptionList: !prevState.showOptionList
      };
    });
  };

  // This method handles the setting of name in select text area
  // and list display on selection
  handleOptionClick = (onChange, name, children, value) => {
    onChange({ target: { name: name, value: value } });
    this.setState({
      defaultSelectText: children,
      showOptionList: false
    });
  };

  getDefaultSelectText = (props) => {
    if (props.value === "" || props.value) {
      const defaultOption = props.children.flat().find(e => typeof e === 'object' && e !== null ? e.props.value === props.value : false);
      this.setState({
        defaultSelectText: defaultOption ? defaultOption.props.children : ""
      })
    }
  }

  render() {
    const { children, placeholder, onChange, name, id, value } = this.props;
    const { showOptionList, defaultSelectText } = this.state;
    return (
      <div className={classNames({
        "custom-select-container": true,
        "form-control": true,
        "disabled": !!this.props.disabled,
        "is-invalid": !!this.props.invalid,
      })}
      >
        <div
          onClick={!!this.props.disabled ? () => null : this.handleListDisplay}
          className={showOptionList ? `selected-text active ${value === '' && 'placeholder'}` : `selected-text ${value === '' && 'placeholder'}`}
        >
          {defaultSelectText ? defaultSelectText : placeholder}
        </div>

        {showOptionList && (
          <ul className="select-options">
            {children.map((option, idx) => {
              if (!option) {
                return null;
              }
              if (Array.isArray(option)) {
                return option.map((e, idx) => {
                  if (e) {
                    return (<li
                      key={idx}
                      className="custom-select-option"
                      name={name}
                      id={id}
                      value={e.props.value}
                      onClick={() => this.handleOptionClick(onChange, name, e.props.children, e.props.value)}
                    >
                      {e.props.children}
                    </li>
                    )
                  }
                  return null;
                })
              } else {
                return (
                  <li hide-guide
                    key={idx}
                    className={`${(option.props.className) ? 'awselect-carret-hide' : 'custom-select-option'}`}
                    name={name}
                    id={id}
                    value={option.props.value}
                    onClick={() => this.handleOptionClick(onChange, name, option.props.children, option.props.value)}
                  >
                    {option.props.children}
                  </li>
                );
              }
            })}
          </ul>
        )}
      </div>
    );
  }
}
